import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSideBar from './AdminSideBar';

function AdminEvents() {
    const [events, setEvents] = useState([]);
    const [eventTitle, setEventTitle] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventRegistrationLink, setEventRegistrationLink] = useState('');
    const [eventFile, setEventFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setEventFile(selectedFile);
        setFileName(selectedFile ? selectedFile.name : '');
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Convert date to DD/MM/YYYY format for consistent display
        const formattedDate = new Date(eventDate).toLocaleDateString('en-IN');

        const newEvent = {
            eventTitle,
            eventDate: formattedDate,
            eventRegistrationLink,
            eventFile, // File will be uploaded separately in FormData
        };

        try {
            const formData = new FormData();
            formData.append('eventTitle', eventTitle);
            formData.append('eventDate', formattedDate);
            formData.append('eventRegistrationLink', eventRegistrationLink);
            if (eventFile) {
                formData.append('eventFile', eventFile);
            }

            const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Event posted:', data);
                setEvents([...events, newEvent]);
                setAlertMessage('Event posted successfully!');
                setAlertType('success');
                resetForm();
            } else {
                setAlertMessage(`Failed to post event: ${response.status}`);
                setAlertType('error');
            }
        } catch (error) {
            setAlertMessage('Error posting event. Please try again.');
            setAlertType('error');
            console.error('Error posting event:', error);
        }
    };

    const resetForm = () => {
        setEventTitle('');
        setEventDate('');
        setEventRegistrationLink('');
        setEventFile(null);
        setFileName('');
    };

    return (
        <>
            <AdminHeader />

            <div className="row card-group">
                <div className="col-lg-3 order-2 order-lg-1">
                    <AdminSideBar />
                </div>
                <div className="col-lg-9 order-1 order-lg-2">
                    <div className="container">
                        {/* Alert message */}
                        {alertMessage && (
                            <div className={`alert mt-3 ${alertType === 'success' ? 'alert-success' : 'alert-danger'} alert-dismissible  `} role="alert">
                                {alertMessage}
                                <button type="button" className="btn btn-close close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                        )}

                        <h2 className="mt-0 h5">Post Events Here</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mt-3">
                                <label className="fw-bold">Event Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={eventTitle}
                                    onChange={(e) => setEventTitle(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className="fw-bold">Event Date:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={eventDate ? new Date(eventDate).toISOString().split('T')[0] : ''}
                                    onChange={(e) => setEventDate(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className="fw-bold">Event Registration Link:</label>
                                <input
                                    type="url"
                                    className="form-control"
                                    value={eventRegistrationLink}
                                    onChange={(e) => setEventRegistrationLink(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className="fw-bold">Upload Poster (Image/PDF):</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    accept="image/*,.pdf"
                                    onChange={handleFileChange}
                                />
                                {fileName && <small className="text-muted mt-2 d-block">Selected File: {fileName}</small>}
                            </div>
                            <div className="text-end">
                                <button type="submit" className="button ally-focus-within py-3  rounded-2  bg-gradient w-25 mt-3">
                                    <big>
                                        Submit
                                    </big>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminEvents;
