import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSideBar from './AdminSideBar';

function AdminExamNotifications() {
    const [examNotifications, setExamNotifications] = useState([]);
    const [notificationTitle, setNotificationTitle] = useState('');
    const [file, setFile] = useState(null);
    const [notificationType, setNotificationType] = useState(''); // Type: BTech, MBA, MTech
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newNotification = {
            title: notificationTitle,
            type: notificationType,
        };

        try {
            const formData = new FormData();
            formData.append('examNotification', JSON.stringify(newNotification));
            if (file) {
                formData.append('file', file);
            }

            const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Notification posted:', data);
                setExamNotifications([...examNotifications, newNotification]);
                setAlertMessage('Notification posted successfully!');
                setAlertType('success');
                resetForm();
            } else {
                console.error('Failed to post notification:', response.status);
                setAlertMessage('Failed to post notification. Please try again.');
                setAlertType('danger');
            }
        } catch (error) {
            console.error('Error posting notification:', error);
            setAlertMessage('Error posting notification. Please try again.');
            setAlertType('danger');
        }
    };

    const resetForm = () => {
        setNotificationTitle('');
        setFile(null);
        setNotificationType('');
    };

    return (
        <>
            <AdminHeader />
            <div className="row card-group">
                <div className="col-lg-3 order-2 order-lg-1">
                    <AdminSideBar />
                </div>
                <div className='col-lg-9 order-1 order-lg-2'>
                    <div className='container pt-0'>
                        <div className="d-flex justify-content-between mb-3">
                            <h2 className='h5 m-0'>Academic Notifications </h2>
                            <a className="button button--inline ally-focus-within" href="/Home/AcademicNotifications">View Notifications <i className="fa fa-chevron-right"></i></a>
                        </div>

                        {/* Bootstrap Alert */}
                        {alertMessage && (
                            <div className={`alert mt-3 ${alertType === 'success' ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                {alertMessage}
                                <button type="button" className="btn btn-close close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                        )}

                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className='fw-bold'>Notification Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={notificationTitle}
                                    onChange={(e) => setNotificationTitle(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className='fw-bold'>Type:</label>
                                <select
                                    className="form-select"
                                    value={notificationType}
                                    onChange={(e) => setNotificationType(e.target.value)}
                                    required
                                >
                                    <option value="">Select Type</option>
                                    <option value="BTech">BTech</option>
                                    <option value="MBA">MBA</option>
                                    <option value="MTech">MTech</option>
                                </select>
                            </div>
                            <div className="form-group mt-3">
                                <label className='fw-bold'>Upload File (Image/PDF):</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    accept="image/*,.pdf"
                                    onChange={handleFileChange}
                                    required
                                />
                            </div>
                            <div className="text-end">
                                <button type="submit" className="button ally-focus-within py-3  rounded-2  bg-gradient w-25 mt-3">
                                    <big>
                                        Submit
                                    </big>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminExamNotifications;
